<template>
    <div class="account">
        <div class="userTitle">
            {{ $t('zhang-hu-yu-an-quan') }}
        </div>

        <el-form label-width="2.16rem">
            <el-form-item :label="$t('wo-de-id-zhang-hao')">
                <div class="form-item">
                    <div class="val bold">{{ userInfo.id }}</div>
                    <el-button
                        type="warning"
                        size="small"
                        plain
                        @click="$router.push('/account/manage')"
                        v-if="isVendor"
                        >{{ $t('quan-xian-guan-li') }}</el-button
                    >
                </div>
                <div class="tips">
                    {{
                        $t(
                            'mei-ge-ren-de-id-zhang-hao-du-shi-xi-tong-sui-ji-sheng-cheng-de-bu-ke-xiu-gai-ke-yi-shi-yong-id-zhang-hao-jin-hang-deng-lu'
                        )
                    }}
                </div>
            </el-form-item>

            <el-form-item :label="$t('shang-ci-deng-lu-shi-jian')" v-if="loginInfo">
                <div class="form-item">
                    <div class="val">{{ loginInfo.time }}</div>
                    <div class="val">{{ $t('zhang-hao-ming') }}:{{ loginInfo.username }}</div>
                </div>
            </el-form-item>
            <el-form-item :label="$t('deng-lu-mi-ma')">
                <div class="form-item">
                    <el-button type="warning" size="small" plain @click="$router.push('/account/changePassword')">{{
                        $t('xiu-gai-mi-ma')
                    }}</el-button>
                </div>
            </el-form-item>
            <el-form-item :label="$t('shou-ji-hao')">
                <div class="form-item">
                    <div class="val">{{ $t('yi-bang-ding-shou-ji-hao-ma') }}：{{ phone }}</div>
                    <el-button type="warning" size="small" plain @click="$router.push('/account/changePhone')">{{
                        $t('bian-geng-shou-ji-hao')
                    }}</el-button>
                </div>
            </el-form-item>
            <el-form-item :label="$t('deng-lu-you-xiang')">
                <div class="form-item">
                    <div class="val">{{ $t('yi-bang-ding-you-xiang') }}：{{ userInfo.email }}</div>
                    <el-button type="warning" size="small" plain @click="$router.push('/account/changeEmail')">{{
                        $t('bian-geng-you-xiang')
                    }}</el-button>
                </div>
            </el-form-item>
        </el-form>
    </div>
</template>
<script>
import { mapState } from 'vuex';
import { formatPhone } from '../../utils/Sms';
export default {
    name: 'account',
    data() {
        return {
            loginInfo: null
        };
    },
    computed: {
        ...mapState(['userInfo']),
        phone() {
            return formatPhone(this.userInfo.phone);
        }
    },
    mounted() {
        this.$http.get('/pointsRecord/lastLogin').then(res => {
            console.log(res);
            this.loginInfo = res;
        });
    }
};
</script>
<style lang="scss" scoped>
@import '../../styles/variables';
.el-form {
    margin: 40px 0;
    max-width: 100%;
}

.form-item {
    display: flex;
    align-items: center;
    height: 40px;

    .val {
        font-size: 12px;
        color: #292c33;
        line-height: 17px;
        margin-right: 30px;
    }
    .el-button--warning.is-plain {
        background-color: transparent;
        border-width: 0px;
        &:hover {
            color: mix($--color-black, $--color-warning, $--button-active-shade-percent);
        }
        margin-left: -15px;
    }
}

.el-form-item {
    margin-bottom: 20px;
}

.tips {
    font-size: 12px;
    color: #bcc1cc;
    line-height: 17px;
}
.userTitle {
    border: none;
    padding: 0;
    border-bottom: 1px solid #bcc1cc;
    margin: 0 20px;
}
</style>
